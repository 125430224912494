import _helper from "../../helper";
import _ActionSet from "../../ActionSet";
import _types from "../../types";
import _index from "../index";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = exports && exports.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalContent = exports.Action = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
var index_1 = _index;
var Action;
(function (Action) {
  Action["LOADING"] = "LOADING";
  Action["LOADED"] = "LOADED";
})(Action = exports.Action || (exports.Action = {}));
/**
 * A set of Actions for updating the modal component
 * @public
 */
var ModalContent = /** @class */function (_super) {
  __extends(ModalContent, _super);
  function ModalContent(app, options) {
    return _super.call(this, app, types_1.Group.Modal, types_1.Group.Modal, options ? options.id : undefined) || this;
  }
  ModalContent.prototype.loaded = function () {
    this.dispatch(Action.LOADED);
  };
  ModalContent.prototype.loading = function () {
    this.dispatch(Action.LOADING);
  };
  /**
   * @private
   */
  ModalContent.prototype.dispatch = function (action) {
    switch (action) {
      case Action.LOADED:
        this.dispatchModalAction(index_1.Action.UPDATE_CONTENT, {
          loading: false
        });
        break;
      case Action.LOADING:
        this.dispatchModalAction(index_1.Action.UPDATE_CONTENT, {
          loading: true
        });
        break;
    }
    return this;
  };
  /**
   * @internal
   */
  ModalContent.prototype.dispatchModalAction = function (type, payload) {
    return __awaiter(this, void 0, void 0, function () {
      var updateAction;
      return __generator(this, function (_a) {
        updateAction = helper_1.actionWrapper({
          type: type,
          group: types_1.Group.Modal,
          payload: __assign({}, payload)
        });
        this.app.dispatch(updateAction);
        return [2 /*return*/];
      });
    });
  };

  return ModalContent;
}(ActionSet_1.ActionSet);
exports.ModalContent = ModalContent;
export default exports;
export const __esModule = exports.__esModule;
const _ModalContent = exports.ModalContent,
  _Action = exports.Action;
export { _ModalContent as ModalContent, _Action as Action };